import React, {useEffect, useState} from 'react';
import './App.css';
import {
  BgImage,
  Box,
  BoxContainer,
  BoxImg,
  Header,
  HeaderContainer,
  HEADLINE646,
  HEADLINE647,
  HEADLINE648,
  HEADLINE649,
  Image1,
  IMAGE645,
  IMAGE650,
  ImgA,
  ImgBg1,
  ImgOverlay,
  TxtName1,
  TxtName3,
  TxtTime1,
  Wrapper,
  TxtDes,
  Box1,
  FrameCount,
  TxtTime,
  TxtPre,
  FrameImage,
  ImageWedding1,
  FrameImageWedding,
  ImgThiep,
  FrameTime,
  FrameImage1,
  ImageWedding2,
  ImgLogo1,
  FrameCollection,
  TxtTitle,
  FrameCuple,
  ImageWedding3,
  FrameColumn,
  FrameImageWedding1,
  FrameCupleColumn,
  FrameInfo,
  Frame1,
  FrameMap,
  ImgPersion,
  ImgFlower,
  FrameLocation,
  FrameName1,
  FrameViewMap,
  TxtNamePer,
  FramePhone,
  ImgPhone1,
  TxtPhone,
  ImgMapLogo,
  TxtLocation,
  ImgNavigate,
  ImgThank,
  TxtThank,
  FrameThank,
  FrameMusic,
  FrameAdd,
  TxtDes1,
  TxtCustomer,
  TxtDes2,
  FrameNameCustomer,
  FrameAllCus
} from './styles';
import ImageLogo1 from '../src/assets/images/image1.svg';
import ImgPhone from '../src/assets/images/phone.svg';
import ImgMap from '../src/assets/images/img_map.svg';
import ImgFlower1 from '../src/assets/images/img_flower1.svg';
import ThankYou from '../src/assets/images/thankyou.png';
import Wedding2 from '../src/assets/images/wedding2.jpg';
import Wedding3 from '../src/assets/images/wedding3.jpg';
import Wedding4 from '../src/assets/images/wedding4.jpg';
import Wedding5 from '../src/assets/images/wedding5.jpg';
import Wedding6 from '../src/assets/images/wedding6.jpg';
import Wedding8 from '../src/assets/images/wedding8.jpg';
import Wedding9 from '../src/assets/images/wedding9.jpg';
import Wedding10 from '../src/assets/images/wedding10.jpg';
import Wedding11 from '../src/assets/images/wedding11.jpg';
import Wedding12 from '../src/assets/images/wedding12.jpg';
import Wedding13 from '../src/assets/images/wedding13.jpg';
import Wedding14 from '../src/assets/images/wedding14.jpg';
import Wedding15 from '../src/assets/images/wedding15.jpg';
import Wedding16 from '../src/assets/images/wedding16.jpg';
import Wedding17 from '../src/assets/images/wedding17.jpg';
import Wedding18 from '../src/assets/images/wedding18.jpg';
import Wedding19 from '../src/assets/images/wedding19.jpg';
import Wedding20 from '../src/assets/images/wedding20.jpg';
import Wedding21 from '../src/assets/images/thiepcuoi.jpg';
import IcMusic from '../src/assets/icons/ic_music.png';
// @ts-ignore
import Music from "../src/assets/music/ngaydautien.mp3";
import 'aos/dist/aos.css';
// import { useParams } from 'react-router-dom';
const AOS = require('aos');

function App() {
  // let { id } = useParams();
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [audio] = useState(new Audio(Music));
  const [playing, setPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      easing: 'ease-in-out',
      duration: 600,
      once: true,
      mirror: true,
    });
    window.addEventListener('load', AOS.refresh);
  }, []);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    const time = setInterval(() => {
      const timeCurrent = new Date().getTime();
      const timeWedding = new Date('2024/01/18 11:30:00').getTime();
      let timeMinus = Math.floor((timeWedding - timeCurrent) / 1000);
      if (timeMinus <= 0) return;
      setDay(Math.floor(timeMinus / (24 * 60 * 60)));
      timeMinus %= 24 * 60 * 60;
      setHour(Math.floor(timeMinus / (60 * 60)));
      timeMinus %= 60 * 60;
      setMinute(Math.floor(timeMinus / 60));
      timeMinus %= 60;
      setSecond(timeMinus);
    }, 1000)
    return () => {
      clearInterval(time);
    }
  }, [])

  const convertTime = (value: number) => {
    if (value < 10) return '0' + value;
    return value;
  }

  return (
    <Wrapper>
      <Header>
        <BgImage/>
        <ImgOverlay/>
        <HeaderContainer>
          <BoxContainer>
            <Box/>
          </BoxContainer>
          <IMAGE645>
            <Image1>
              <ImgBg1></ImgBg1>
            </Image1>
          </IMAGE645>
          <HEADLINE646>
            <TxtTime1>
              {'18.01.2024 -  Diễn Châu, Nghệ An'}
            </TxtTime1>
          </HEADLINE646>
          <HEADLINE647>
            <TxtName1>
              {'Tiến An'}
            </TxtName1>
          </HEADLINE647>
          <HEADLINE648>
            <TxtName1>
              {'Như Quỳnh'}
            </TxtName1>
          </HEADLINE648>
          <HEADLINE649>
            <TxtName3>
              {'&'}
            </TxtName3>
          </HEADLINE649>
          <IMAGE650>
            <BoxImg>
              <ImgA/>
            </BoxImg>
          </IMAGE650>
        </HeaderContainer>
      </Header>
      <FrameImage>
        <FrameImageWedding data-aos="fade-right">
          <ImageWedding1
            src={Wedding2}/>
        </FrameImageWedding>
        <FrameImageWedding data-aos="fade-left">
          <ImageWedding1
            src={Wedding3}/>
        </FrameImageWedding>
        <FrameImageWedding data-aos="fade-right">
          <ImageWedding1
            src={Wedding4}/>
        </FrameImageWedding>
      </FrameImage>
      {/*{id && <FrameAdd>
        <TxtDes1>{'TRÂN TRỌNG KÍNH MỜI'}</TxtDes1>
        <FrameAllCus>
          <FrameNameCustomer>
            <TxtCustomer>{id}</TxtCustomer>
          </FrameNameCustomer>
        </FrameAllCus>
        <TxtDes2>{'Tới dự Lễ Thành Hôn của 2 chúng tôi'}</TxtDes2>
      </FrameAdd>}*/}
      <ImgThiep src={Wedding21}/>
      <FrameTime>
        <TxtDes>
          {'Chúng mình cùng đếm ngược thời gian'}
        </TxtDes>
        <FrameCount>
          <Box1>
            <TxtTime>{convertTime(day)}</TxtTime>
            <TxtPre>{'Ngày'}</TxtPre>
          </Box1>
          <Box1>
            <TxtTime>{convertTime(hour)}</TxtTime>
            <TxtPre>{'Giờ'}</TxtPre>
          </Box1>
          <Box1>
            <TxtTime>{convertTime(minute)}</TxtTime>
            <TxtPre>{'Phút'}</TxtPre>
          </Box1>
          <Box1>
            <TxtTime>{convertTime(second)}</TxtTime>
            <TxtPre>{'Giây'}</TxtPre>
          </Box1>
        </FrameCount>
        <FrameImage1>
          <FrameImageWedding data-aos="flip-left">
            <ImageWedding2
              src={Wedding5}/>
          </FrameImageWedding>
          <FrameImageWedding data-aos="flip-right">
            <ImageWedding2
              src={Wedding6}/>
          </FrameImageWedding>
          <FrameImageWedding data-aos="flip-up">
            <ImageWedding2
              src={Wedding5}/>
          </FrameImageWedding>
        </FrameImage1>
        <FrameCollection>
          <ImgLogo1 src={'https://w.ladicdn.com/s400x350/5c7362c6c417ab07e5196b05/r5gt-20201125032553.png'}/>
          <TxtTitle>{'BỘ SƯU TẬP ẢNH CƯỚI'}</TxtTitle>
          <FrameImage1>
            <FrameImageWedding data-aos="zoom-in">
              <ImageWedding1
                src={Wedding8}/>
            </FrameImageWedding>
            <FrameImageWedding data-aos="zoom-in-up">
              <ImageWedding1
                src={Wedding9}/>
            </FrameImageWedding>
            <FrameCuple>
              <FrameImageWedding data-aos="zoom-in-down">
                <ImageWedding3
                  src={Wedding10}/>
              </FrameImageWedding>
              <FrameImageWedding data-aos="zoom-in-left">
                <ImageWedding3
                  src={Wedding11}/>
              </FrameImageWedding>
            </FrameCuple>
            <FrameImageWedding data-aos="zoom-in-right">
              <ImageWedding1
                src={Wedding13}/>
            </FrameImageWedding>
            <FrameImageWedding data-aos="fade-right">
              <ImageWedding1
                src={Wedding12}/>
            </FrameImageWedding>
            <FrameColumn>
              <FrameImageWedding1 data-aos="fade-right">
                <ImageWedding1
                  src={Wedding14}/>
              </FrameImageWedding1>
              <FrameCupleColumn>
                <FrameImageWedding data-aos="fade-left">
                  <ImageWedding1
                    src={Wedding15}/>
                </FrameImageWedding>
                <FrameImageWedding data-aos="fade-left">
                  <ImageWedding1
                    src={Wedding16}/>
                </FrameImageWedding>
              </FrameCupleColumn>
            </FrameColumn>
            <FrameImageWedding data-aos="fade-right">
              <ImageWedding1
                src={Wedding17}/>
            </FrameImageWedding>
          </FrameImage1>
        </FrameCollection>
      </FrameTime>
      <FrameInfo>
        <Frame1>
          <FrameMap>
            <ImgFlower src={ImageLogo1}/>
            <FrameLocation>
              <FrameName1>
                <TxtNamePer>{'Tiến An'}</TxtNamePer>
                <FramePhone>
                  <ImgPhone1 src={ImgPhone}/>
                  <TxtPhone>{'098.113.6696'}</TxtPhone>
                </FramePhone>
              </FrameName1>
              <FrameViewMap>
                <ImgMapLogo src={ImgMap}/>
                <TxtLocation>
                  {'Xóm Xuân Trung,\n Diễn Xuân,\nDiễn Châu, Nghệ An'}
                </TxtLocation>
                <a href="https://maps.app.goo.gl/jRERtxwhY52eEyTH9" target="_blank">
                  <ImgNavigate src={'https://w.ladicdn.com/s400x350/5f55b1b2c0ad9315a24503fd/ggmaps-20230227153936-ajnqp.png'}/>
                </a>
              </FrameViewMap>
            </FrameLocation>
          </FrameMap>
          <ImgPersion src={Wedding19}/>
        </Frame1>
        <Frame1>
          <FrameMap>
            <ImgFlower src={ImgFlower1}/>
            <FrameLocation>
              <FrameName1>
                <TxtNamePer>{'Như Quỳnh'}</TxtNamePer>
                <FramePhone>
                  <ImgPhone1 src={ImgPhone}/>
                  <TxtPhone>{'096.400.4621'}</TxtPhone>
                </FramePhone>
              </FrameName1>
              <FrameViewMap>
                <ImgMapLogo src={ImgMap}/>
                <TxtLocation>
                  {'Xóm 2, Diễn Tháp,\nDiễn Châu, Nghệ An'}
                </TxtLocation>
                <a href="https://maps.app.goo.gl/QwHv5y7EB2mgDukYA" target="_blank">
                  <ImgNavigate src={'https://w.ladicdn.com/s400x350/5f55b1b2c0ad9315a24503fd/ggmaps-20230227153936-ajnqp.png'}/>
                </a>
              </FrameViewMap>
            </FrameLocation>
          </FrameMap>
          <ImgPersion src={Wedding18}/>
        </Frame1>
        <FrameImageWedding>
          <ImageWedding1
            src={Wedding20}/>
        </FrameImageWedding>
      </FrameInfo>
      <FrameThank>
        <ImgThank src={ThankYou}/>
        <TxtThank>
          Cảm ơn bạn rất nhiều vì đã tham dự đám cưới của chúng tôi.  Đó là ngày tuyệt vời nhất trong cuộc đời của chúng tôi và nó sẽ không hoàn hảo nếu không có bạn!
        </TxtThank>
      </FrameThank>
      <FrameMusic
        className={`animated-button ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setPlaying(true)}
      >
        <img src={IcMusic}/>
      </FrameMusic>
    </Wrapper>
  );
}

export default App;
