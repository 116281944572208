import {styled} from "styled-components";
import Wedding1 from '../src/assets/images/wedding1.jpg';

export const Wrapper = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    min-height: 100%;
    overflow: hidden;
    margin: 0 auto;
    width: 420px;
`;

export const Header = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    position: relative;
    height: 648.965px;
`;

export const BgImage = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    background-size: cover;
    background-attachment: scroll;
    background-origin: content-box;
    background-image: url(${Wedding1});
   
    background-position: left center;
    background-repeat: repeat;
`;

export const ImgOverlay = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
`;

export const HeaderContainer = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: relative;
    margin: 0 auto;
    height: 100%;
`;

export const BoxContainer = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 419px;
    height: 328.053px;
    top: 320.912px;
    left: 0.5px;
`;

export const Box = styled.div`
      -webkit-text-size-adjust: none;
  direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(180deg, #FFFFFF00, rgba(37, 38, 40, 1));
    border-radius: 0px;
`;

export const IMAGE645 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 330.789px;
    height: 173.231px;
    top: 374.299px;
    left: 44px;
`;

export const Image1 = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
`;

export const ImgBg1 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    background-attachment: scroll;
    background-origin: content-box;
    position: absolute;
    margin: 0 auto;
    pointer-events: none;
    width: 330.789px;
    height: 173.231px;
    top: 0px;
    left: 0px;
    background-image: url("https://w.ladicdn.com/s650x500/5c7362c6c417ab07e5196b05/t6urt6-20201125030742.png");
`;

export const HEADLINE646 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 232px;
    top: 610.299px;
    left: 94px;
`;

export const TxtTime1 = styled.h3`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    display: inline-block;
    word-break: break-word;
    background-size: cover;
    background-position: center center;
    font-family: "Open Sans", sans-serif;
    color: rgb(217, 194, 160);
    font-size: 12px;
    text-align: center;
    line-height: 1.6;
`;

export const HEADLINE647 = styled.div`
        -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 314px;
    top: 484.299px;
    left: 46px;
`;

export const TxtName1 = styled.h3`
    -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    display: inline-block;
    word-break: break-word;
    background-size: cover;
    background-position: center center;
    color: rgb(255, 255, 255);
    font-size: 52px;
    line-height: 1.6;
    font-family: Prata,sans-serif;
`;

export const HEADLINE648 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 333px;
    top: 536.299px;
    left: 86px;
`;

export const HEADLINE649 = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 27px;
    top: 506.299px;
    left: 270px;
`;

export const TxtName3 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    display: inline-block;
    word-break: break-word;
    background-size: cover;
    background-position: center center;
    color: rgba(255, 255, 255, 0);
    font-size: 32.58px;
    line-height: 1.6;
    -webkit-text-stroke: 1px rgb(255, 255, 255);
    font-family: Prata,sans-serif;
`;

export const IMAGE650 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 193px;
    height: 193px;
    top: 323.299px;
    left: 244.5px;
`;

export const BoxImg = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
`;

export const ImgA = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    background-attachment: scroll;
    background-origin: content-box;
    position: absolute;
    margin: 0 auto;
    pointer-events: none;
    width: 193px;
    height: 193px;
    top: 0px;
    left: 0px;
    background-image: url("https://w.ladicdn.com/5f55b1b2c0ad9315a24503fd/4-20230205095530-5r0ku.gif");
`;

export const SECTION3 = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    position: relative;
    height: 1115.47px;
`;

export const BgImage1 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    background-size: cover;
    background-attachment: scroll;
    background-origin: content-box;
    background-image: url("https://w.ladicdn.com/s768x1115/5c7362c6c417ab07e5196b05/hhhh-20201125074735.jpg");
    background-position: center top;
    background-repeat: repeat;
`;

export const ImgContainer = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: relative;
    margin: 0 auto;
    height: 100%;
`;

export const IMAGE508 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 400px;
    height: 561px;
    top: 10.544px;
    left: 9.8945px;
`;

export const TxtDes = styled.h3`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    display: inline-block;
    word-break: break-word;
    background-size: cover;
    background-position: center center;
    color: rgb(26, 26, 26);
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
    font-family: Prata,sans-serif;
`;

export const BOX480 = styled.div`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 59px;
    height: 74.96px;
    top: 55.046px;
    left: 82px;
`;

export const Box1 = styled.div`
    -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    //position: absolute;
    //width: 100%;
    //height: 100%;
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 5px -5px #000;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-color: rgb(54, 121, 67);
    border-width: 1px;
    border-radius: 5px;
    width: 59px;
    height: 74.96px;
    flex-direction: column;
    justify-content: center;
    display: flex;
`;

export const FrameCount = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  gap: 10px;
  justify-content: center;
`;

export const TxtTime = styled.span`
  color: rgb(54, 121, 67);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
`;

export const TxtPre = styled.h3`
      -webkit-text-size-adjust: none;
    direction: ltr;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    display: inline-block;
    word-break: break-word;
    background-size: cover;
    background-position: center center;
    color: rgb(84, 84, 84);
    font-size: 14px;
    text-align: center;
    line-height: 1.6;
    font-family: Prata,sans-serif;  
`;

export const FrameImage = styled.div`
  display: flex;
  flex-direction: column;
  background: #F8F5F2;
  padding: 10px;
  overflow: hidden;
  gap: 12px;
`;

export const ImageWedding1 = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const FrameImageWedding = styled.div`
  display: flex;
  flex: 1;
  background: white;
  padding: 12px;
  overflow: hidden;
`;

export const ImgThiep = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const FrameTime = styled.div`
  display: flex;
  flex-direction: column;
  background: #F4F0EA;
  padding: 20px 10px 0 10px;
  justify-content: center;
  align-content: center;
`;

export const FrameImage1 = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 12px;
`;

export const ImageWedding2 = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

export const ImgLogo1 = styled.img`
  height: auto;
  width: 100px;
  object-fit: cover;
  margin: 0;
`;

export const FrameCollection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`;

export const TxtTitle = styled.div`
  text-transform: uppercase;
  color: rgb(117, 87, 47);
  font-size: 28px;
  text-align: center;
  line-height: 1.6;
  font-family: Prata,sans-serif;
  margin: 20px 0;
`;

export const FrameCuple = styled.div`
  display: flex;
  flex-direction: row;
  //width: 100%;
  gap: 10px;
`;

export const ImageWedding3 = styled.img`
  display: flex;
  flex: 1;
  max-width: 100%;
  height: auto;
  object-fit: cover;
`;

export const FrameColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const FrameImageWedding1 = styled.div`
  display: flex;
  flex: 2;
  background: white;
  padding: 12px;
  overflow: hidden;
`;

export const FrameCupleColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

export const FrameInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #F4F0EA;
  padding: 20px 10px 50px 10px;
  justify-content: center;
  align-content: center;
  gap: 20px;
`;

export const Frame1 = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FrameMap = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

export const ImgPersion = styled.img`
  display: flex;
  flex: 1;
  height: 210px;
  width: auto;
  object-fit: cover;
  border-radius: 4px;
`;

export const ImgFlower = styled.img`
  width: 80px;
  height: 80px;
  object-fit: fill;
`;

export const FrameLocation = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #EEE7DB;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 10px;
`;

export const FrameName1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const FrameViewMap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const TxtNamePer = styled.h3`
  font-family: "Quicksand", sans-serif;
  color: rgb(37, 38, 40);
  font-size: 20px;
  font-weight: bold;
`;
export const FramePhone = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
export const ImgPhone1 = styled.img`
  height: 20px;
  width: 20px;
`;
export const TxtPhone = styled.div`
  font-family: "Quicksand", sans-serif;
  color: rgb(37, 38, 40);
  font-size: 13px;
  font-weight: bold;
  line-height: 1.6;
`;

export const ImgMapLogo = styled.img`
  height: 19px;
  width: 19px;
`;
export const TxtLocation = styled.div`
  display: flex;
  font-family: "Quicksand", sans-serif;
  color: rgba(37, 38, 40, 0.7);
  font-size: 13px;
  line-height: 1.6;
  white-space: pre-line;
`;
export const ImgNavigate = styled.img`
  width: 63.464px;
  height: 31.732px;
  cursor: pointer;
`;

export const ImgThank = styled.img`
  width: 100%;
  height: auto;
`;

export const FrameThank = styled.div`
  display: flex;
  background: #F4F0EA;
  position: relative;
`;

export const TxtThank = styled.div`
    display: flex;
    vertical-align: baseline;
    box-sizing: border-box;
    word-break: break-word;
    font-family: "Quicksand", sans-serif;
    color: rgb(37, 38, 40);
    font-size: 14px;
    line-height: 1.6;
    width: 244px;
    position: absolute;
    bottom: 100px;
    right: 5px;
`;

export const FrameMusic = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 50px;
  border: #3D7940 solid 1px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  &.hover {
    opacity: 0.7;
  }
`;

export const FrameAdd = styled.div`
  background: #F9F9F9;
  padding: 20px;
`;

export const TxtDes1 = styled.h3`
  text-transform: uppercase;
  color: rgb(117, 87, 47);
  font-size: 16px;
  text-align: center;
  //line-height: 1.6;
  font-family: Prata,sans-serif;
`;

export const TxtCustomer = styled.h3`
  font-size: 24px;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  font-optical-sizing: auto;
  opacity: 1;
  font-family: "Dancing Script",sans-serif;
  line-height: 0;
`;

export const TxtDes2 = styled.h3`
  color: rgb(117, 87, 47);
  font-size: 16px;
  text-align: center;
  //line-height: 1.6;
  font-family: Prata,sans-serif;
`;

export const FrameNameCustomer = styled.div`
  display: flex;
  width: fit-content;
  padding: 0 10px;
  border-bottom:  #282c34 dotted 1px;
`;

export const FrameAllCus = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;